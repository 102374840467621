<template>
    <div id="spinner">
        <svg class="spinner-container" width="80px" height="80px" viewBox="0 0 52 52">
        <circle class="path" cx="26px" cy="26px" r="20px" fill="none" stroke-width="4px" />
        </svg>
    </div>
</template>

<style scoped>
    #spinner {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .spinner-container {
        animation: rotate 2s linear infinite;
        -webkit-animation: rotate 2s linear infinite;
    }

    .spinner-container .path {
        stroke-dasharray: 1,150; /* 1%, 101% circumference */
        stroke-dashoffset: 0;
        stroke:#25D366;
        stroke-linecap: round;
        animation: dash 1.5s ease-in-out infinite;
        -webkit-animation: dash 1.5s ease-in-out infinite;
    }

    @keyframes rotate{
        100% { transform: rotate(360deg); }
    }

    @keyframes dash {
        0% {
        stroke-dasharray: 1,150;  /* 1%, 101% circumference */
        stroke-dashoffset: 0;
        }
        50% {
        stroke-dasharray: 90,150; /* 70%, 101% circumference */
        stroke-dashoffset: -35;   /* 25% circumference */
        }
        100% {
        stroke-dasharray: 90,150; /* 70%, 101% circumference */
        stroke-dashoffset: -124;  /* -99% circumference */
        }
    }

    @-webkit-keyframes rotate{
        100% { transform: rotate(360deg); }
    }

    @-webkit-keyframes dash {
        0% {
        stroke-dasharray: 1,150;  /* 1%, 101% circumference */
        stroke-dashoffset: 0;
        }
        50% {
        stroke-dasharray: 90,150; /* 70%, 101% circumference */
        stroke-dashoffset: -35;   /* 25% circumference */
        }
        100% {
        stroke-dasharray: 90,150; /* 70%, 101% circumference */
        stroke-dashoffset: -124;  /* -99% circumference */
        }
    }

</style>
