<template>
  <div class="phone-number-container">
    <div class="go-back">
      <button class="btn btn-primary btn-sm" @click="goBack">Go Back</button>
    </div>
    <div class="phone-number-loaded" v-if="!loading && phoneNumber">
      <div class="message-templates-and-creation">
        <div class="message-templates">
          <div class="phone-number">
            <div class="message-template-container">
              <h5>Message Templates</h5>
              <div class="message-template-list" v-if="messageTemplates.length > 0">
                <div class="message-template" :class="{ 'selected': testingMessageTemplate && messageTemplate.name == testingMessageTemplate.name }" @click="selectTestTemplate(messageTemplate)" v-for="messageTemplate in messageTemplates" v-bind:key="messageTemplate.id">
                  <div class="message-template-name">
                    {{ messageTemplate.name }}
                  </div>
                  <div class="message-template-status">
                    ({{ messageTemplate.language }}) - {{ messageTemplate.id }} - {{ messageTemplate.status }}
                    <p v-if="messageTemplate.rejected_reason !== 'NONE'">
                      <br>
                      {{ messageTemplate.rejected_reason ? ' - ' + messageTemplate.rejected_reason : '' }}
                    </p>
                  </div>
                  <div class="message-template-actions">
                    <button class="btn btn-danger btn-xs" @click="deleteMessageTemplate(messageTemplate)">Delete (Permanent)</button>
                  </div>
                </div>
              </div>
              <div v-else>
                No Templates
              </div>
            </div>
            <div class="message-template-test" v-if="testingMessageTemplate">
              <h5>Test Message Template</h5>
              <div class="message-template-test-info">
                <div class="message-template-test-selected">
                  <div class="message-template-test-name">
                    {{ testingMessageTemplate.name }}
                  </div>
                  <div class="message-template-components" v-for="component in testingMessageTemplate.components" v-bind:key="component.id">
                    <div class="template-header" v-if="component.type == 'header'">
                      Format: {{ component.format }}
                      <b>{{ component.text }}</b>
                      <h5>
                      {{ component.parameters.length }}
                        Header Parameters
                      </h5>
                      <div v-if="component.format=='TEXT'">
                        <div class="parameter" v-for="parameter in component.parameters" v-bind:key="parameter.id">
                          <input type="text" placeholder="Parameter" v-model="parameter.text"/>
                        </div>
                      </div>
                      <div v-if="component.format=='IMAGE'">
                        <input type="text" placeholder="Link" v-model="component.parameters[0].image.link"/>
                      </div>
                      <div v-if="component.format=='VIDEO'">
                        <input type="text" placeholder="Link" v-model="component.parameters[0].video.link"/>
                      </div>
                      <div v-if="component.format=='DOCUMENT'">
                        <input type="text" placeholder="Link" v-model="component.parameters[0].document.link"/>
                        <input type="text" placeholder="Filename" v-model="component.parameters[0].document.filename"/>
                      </div>
                      <div v-if="component.format=='LOCATION'" class="location-params">
                        <input type="text" placeholder="Latitud" v-model="component.parameters[0].location.latitude"/>
                        <input type="text" placeholder="Longitud" v-model="component.parameters[0].location.longitude"/>
                        <input type="text" placeholder="Nombre" v-model="component.parameters[0].location.name"/>
                        <input type="text" placeholder="Dirección" v-model="component.parameters[0].location.address"/>
                      </div>
                    </div>
                    <div class="template-body" v-if="component.type == 'body'">
                      {{ component.text }}
                      <h5>
                      {{ component.parameters.length }}
                        Body Parameters
                      </h5>
                      <div class="parameter" v-for="parameter in component.parameters" v-bind:key="parameter.id">
                        <input type="text" placeholder="Parameter" v-model="parameter.text"/>
                      </div>
                    </div>
                    <div class="template-header" v-if="component.type == 'footer'">
                      <i>{{ component.text }}</i>
                    </div>
                    <div class="template-header" v-if="component.type == 'button'">
                      index: {{ component.index }}
                      <div class="quick-reply-button" v-if="component.sub_type === 'quick_reply'">
                        {{ component.text }}
                        Payload:
                        <input type="text" placeholder="Payload" v-model="component.parameters[0].payload"/>
                      </div>
                      <div class="quick-reply-button" v-if="component.sub_type === 'phone_number'">
                        Call
                        Texto:
                        <input type="text" placeholder="Phone Number" v-model="component.parameters[0].text"/>
                      </div>
                      <div class="quick-reply-button" v-if="component.sub_type === 'url'">
                        Url: {{ component.url }}
                        <input v-if="component.parameters?.length > 0" type="text" placeholder="sufijo url" v-model="component.parameters[0].text"/>
                      </div>
                      <div class="quick-reply-button" v-if="component.sub_type === 'copy_code'">
                        Coupon Code
                        Código:
                        <input type="text" placeholder="Código" v-model="component.parameters[0].coupon_code"/>
                      </div>
                    </div>
                    <hr>
                  </div>
                </div>
              </div>
              <br>
              <div class="message-template-test-input">
                <label for="testPhoneNumber">Test phone number:</label>
                <input type="text" v-model="testPhoneNumber" placeholder="569..."/>
              </div>
              <button :disabled="!testingMessageTemplate" class="btn btn-primary btn-xs" @click="testMessageTemplate">Test</button>
              <p v-if="!testingMessageTemplate">
                Select a message template to test
              </p>
            </div>
          </div>
        </div>
        <div class="message-template-creator">
          <h5>Create Message Template</h5>
          <div class="message-template-creation-form">
            <label for="messageTemplateName">Name:</label>
            <input type="text" v-model="messageTemplateName" placeholder="My Template"/>
            <label for="messageTemplateLanguage">Language:</label>
            <select v-model="messageTemplateLanguage">
              <option value="es">Español</option>
              <option value="en">English</option>
              <option value="fr">French</option>
              <!-- Add more options as needed -->
            </select>
            <label for="messageTemplateCategory">Category:</label>
            <select v-model="messageTemplateCategory">
              <option value="AUTHENTICATION">AUTHENTICATION</option>
              <option value="MARKETING">MARKETING</option>
              <option value="UTILITY">UTILITY</option>
              <!-- Add more options as needed -->
            </select>
            <label for="messageTemplateComponents">Components:</label>
            <div class="message-template-components">
              <div class="message-template-component-header" v-if="headerComponent">
                <h5>Header</h5>
                <select v-model="headerComponent.format">
                  <option value="TEXT">TEXT</option>
                  <option value="IMAGE">IMAGE</option>
                  <option value="VIDEO">VIDEO</option>
                  <option value="DOCUMENT">DOCUMENT</option>
                  <option value="LOCATION">LOCATION</option>
                  <!-- Add more options as needed -->
                </select>
                <input type="text" v-model="headerComponent.text" placeholder="text" v-if="headerComponent.format == 'TEXT'" @input="onHeaderTextInput(headerComponent)"/>
                <div v-if="headerComponent.format == 'TEXT' && headerComponent.example">
                  <h5>
                    Header Parameters
                  </h5>
                  <div class="parameter" v-for="(parameter, parameterIndex) in headerComponent.example.header_text" v-bind:key="parameterIndex">
                    <input type="text" v-model="headerComponent.example.header_text[parameterIndex]" placeholder="Parameter"/>
                  </div>
                </div>
                <input type="text" v-model="headerComponent.text" placeholder="URL" v-if="['IMAGE', 'VIDEO', 'DOCUMENT'].includes(headerComponent.format)"/>
              </div>
              <div class="message-template-component-body" v-if="bodyComponent">
                <h5>Body</h5>
                <input type="text" v-model="bodyComponent.text" placeholder="My Component" @input="onBodyTextInput(bodyComponent)"/>
                <div v-if="bodyComponent.example">
                  <h5>
                    Body Parameters
                  </h5>
                  <div class="parameter" v-for="(parameter, parameterIndex) in bodyComponent.example.body_text[0]" v-bind:key="parameterIndex">
                    <input type="text" v-model="bodyComponent.example.body_text[0][parameterIndex]" placeholder="Parameter"/>
                  </div>
                </div>
              </div>
              <div class="message-template-component-button" v-if="buttonsComponent">
                <h5>Buttons</h5>
                <div class="message-tempalte-button" v-for="(button, buttonIndex) in buttonsComponent.buttons" v-bind:key="buttonIndex">
                  Botón {{ buttonIndex }} ({{ button.type }})
                  <div class="quick-reply-button" v-if="button.type === 'QUICK_REPLY'">
                    <input type="text" v-model="button.text" placeholder="Texto del botón"/>
                  </div>
                  <div class="quick-reply-button" v-if="button.type === 'PHONE_NUMBER'">
                    <input type="text" v-model="button.text" placeholder="Texto del botón"/>
                    <input type="text" v-model="button.phone_number" placeholder="Phone NUmber"/>
                  </div>
                  <div class="quick-reply-button" v-if="button.type === 'URL'">
                    <input type="text" v-model="button.text" placeholder="Texto del botón"/>
                    <input type="text" v-model="button.url" placeholder="Url" @input="onUrlButtonUrlInput(button)"/>
                    Parámetros
                    <div class="parameter" v-for="(parameter, parameterIndex) in button.example" v-bind:key="parameterIndex">
                      <input type="text" v-model="button.example[parameterIndex]" placeholder="Parameter"/>
                    </div>
                  </div>
                  <div class="quick-reply-button" v-if="button.type === 'COPY_CODE'">
                    <input type="text" v-model="button.text" placeholder="Texto del botón"/>
                    <input type="text" v-model="button.example[0]" placeholder="Code"/>
                  </div>
                </div>
                <button @click="addQuickReplyButton(buttonsComponent)">
                  Add Quick Reply Button
                </button>
                <button @click="addPhoneNumberButton(buttonsComponent)">
                  Add Phone Number Button
                </button>
                <button @click="addUrlrButton(buttonsComponent)">
                  Add Url Button
                </button>
                <button @click="addCopyCodeButton(buttonsComponent)">
                  Add Copy Code Button
                </button>
              </div>
              <div class="message-template-component-footer" v-if="footerComponent">
                <h5>Footer</h5>
                <input type="text" v-model="footerComponent.text" placeholder="My Component"/>
              </div>
            </div>
            <button @click="addHeaderComponent" v-if="headerComponent === undefined">
              Add Header Component
            </button>
            <button @click="addBodyComponent" v-if="bodyComponent === undefined">
              Add Body Component
            </button>
            <button @click="addFooterComponent" v-if="footerComponent === undefined">
              Add Footer Component
            </button>
            <button @click="addButtonsComponent" v-if="buttonsComponent === undefined">
              Add Button Component
            </button>
            <hr>
            <button class="btn btn-primary btn-xs" @click="createMessageTemplate" :disabled="!validTemplate()">Create</button>
          </div>
        </div>
      </div>
      <h4> Permissions needed for 👇 </h4>
      <div class="phone-number-actions">
        <button class="btn btn-whatsapp btn-sm" @click="synchronizeMessageTemplates">
          <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
          Synchronize Message Templates
        </button>
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref, computed } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { MessageTemplate, PhoneNumber, WhatsappTemplate, CreateableTemplateComponent, CreateableHeaderComponent, CreateableBodyComponent, CreateableFooterComponent, CreateableButtonsComponent, ButtonTypeURL } from '../services/AccountService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const phoneNumber = ref<PhoneNumber | undefined>(undefined)

const testPhoneNumber = ref('')
const messageTemplateName = ref('')
const messageTemplateLanguage = ref('')
const messageTemplateCategory = ref('')

const messageTemplates = ref<Array<MessageTemplate>>([])

const testingMessageTemplate = ref<WhatsappTemplate | undefined>(undefined)

const headerComponent = ref<CreateableHeaderComponent | undefined>(undefined)

const bodyComponent = ref<CreateableBodyComponent | undefined>(undefined)

const footerComponent = ref<CreateableFooterComponent | undefined>(undefined)

const buttonsComponent = ref<CreateableButtonsComponent | undefined>(undefined)

const existingComponents = computed(() => {
  const components: CreateableTemplateComponent[] = []

  if (headerComponent.value !== undefined) {
    components.push(headerComponent.value)
  }
  if (bodyComponent.value !== undefined) {
    components.push(bodyComponent.value)
  }
  if (footerComponent.value !== undefined) {
    components.push(footerComponent.value)
  }
  if (buttonsComponent.value !== undefined) {
    components.push(buttonsComponent.value)
  }

  return components
})

function goBack () {
  router.back()
}

function numberOfParameters (text: string) {
  const regex = /\{\{(\d+)\}\}/g
  const matches = text.match(regex)
  const numberOfParameters = matches ? matches.length : 0
  return numberOfParameters
}

function onUrlButtonUrlInput (button: ButtonTypeURL) {
  const numberOfParams = numberOfParameters(button.url)
  if (numberOfParams) {
    button.example = []
    for (let i = 0; i < numberOfParams; i++) {
      button.example.push('')
    }
  } else {
    button.example = null
  }
}

function onBodyTextInput (bodyComponent: CreateableBodyComponent) {
  const numberOfParams = numberOfParameters(bodyComponent.text)
  if (numberOfParams) {
    bodyComponent.example = { body_text: [[]] }
    for (let i = 0; i < numberOfParams; i++) {
      bodyComponent.example.body_text[0].push('')
    }
  } else {
    bodyComponent.example = null
  }
}

function onHeaderTextInput (headerComponent: CreateableHeaderComponent) {
  if (headerComponent.text) {
    const numberOfParams = numberOfParameters(headerComponent.text)
    if (numberOfParams) {
      headerComponent.example = { header_text: [] }
      for (let i = 0; i < numberOfParams; i++) {
        headerComponent.example.header_text.push('')
      }
    } else {
      headerComponent.example = null
    }
  }
}

function selectTestTemplate (messageTemplate: MessageTemplate) {
  testingMessageTemplate.value = {
    name: messageTemplate.name,
    language: { code: messageTemplate.language },
    components: []
  }

  let buttonCounter = 0
  let numberOfParams = 0
  let parameters = []

  for (const component of messageTemplate.components) {
    switch (component.type) {
      case 'HEADER':
        parameters = []
        switch (component.format) {
          case 'TEXT':
            numberOfParams = numberOfParameters(component.text)
            for (let i = 0; i < numberOfParams; i++) {
              parameters.push({ type: 'text', text: '', id: i })
            }
            break
          case 'IMAGE':
            parameters = [{ type: 'image', image: { link: '' } }]
            break
          case 'VIDEO':
            parameters = [{ type: 'video', video: { link: '' } }]
            break
          case 'DOCUMENT':
            parameters = [{ type: 'document', document: { link: '', filename: '' } }]
            break
          case 'LOCATION':
            parameters = [{ type: 'location', location: { latitude: '', longitude: '', name: '', address: '' } }]
            break
        }
        testingMessageTemplate.value.components.push({
          type: component.type.toLowerCase(),
          text: component.text,
          format: component.format,
          parameters
        })
        break
      case 'BODY':
        numberOfParams = numberOfParameters(component.text)
        parameters = []
        for (let i = 0; i < numberOfParams; i++) {
          parameters.push({ type: 'text', text: '', id: i })
        }
        testingMessageTemplate.value.components.push({
          type: component.type.toLowerCase(),
          text: component.text,
          parameters
        })
        break
      case 'FOOTER':
        testingMessageTemplate.value.components.push({
          type: 'footer',
          text: component.text
        })
        break
      case 'BUTTONS':
        for (const button of component.buttons) {
          switch (button.type) {
            case 'QUICK_REPLY':
              testingMessageTemplate.value.components.push({
                type: 'button',
                sub_type: 'quick_reply',
                text: button.text,
                index: buttonCounter.toString(),
                parameters: [{ type: 'payload', payload: button.text }]
              })
              break
            case 'PHONE_NUMBER':
              testingMessageTemplate.value.components.push({
                type: 'button',
                sub_type: 'phone_number',
                text: button.text,
                index: buttonCounter.toString(),
                parameters: [{ type: 'phone_number', text: button.text }]
              })
              break
            case 'URL':
              numberOfParams = 0
              numberOfParams = numberOfParameters(button.url)
              parameters = []
              for (let i = 0; i < numberOfParams; i++) {
                parameters.push({ type: 'text', text: '', index: i })
              }
              testingMessageTemplate.value.components.push({
                type: 'button',
                sub_type: 'url',
                url: button.url,
                index: buttonCounter.toString(),
                parameters: parameters
              })
              break
            case 'COPY_CODE':
              testingMessageTemplate.value.components.push({
                type: 'button',
                sub_type: 'copy_code',
                text: button.text,
                index: buttonCounter.toString(),
                parameters: [
                  {
                    type: 'coupon_code',
                    coupon_code: button.example[0]
                  }
                ]
              })
              break
          }
          buttonCounter++
        }
        break
    }
  }
}

function addBodyComponent () {
  bodyComponent.value = { type: 'BODY', text: '' }
}

function addHeaderComponent () {
  headerComponent.value = { type: 'HEADER', text: '', format: 'TEXT' }
}

function addFooterComponent () {
  footerComponent.value = { type: 'FOOTER', text: '' }
}

function addButtonsComponent () {
  buttonsComponent.value = { type: 'BUTTONS', buttons: [] }
}

function addQuickReplyButton (buttonsComponent: CreateableButtonsComponent) {
  buttonsComponent.buttons.push({ text: '', type: 'QUICK_REPLY' })
}

function addPhoneNumberButton (buttonsComponent: CreateableButtonsComponent) {
  buttonsComponent.buttons.push({ phone_number: '', type: 'PHONE_NUMBER' })
}

function addUrlrButton (buttonsComponent: CreateableButtonsComponent) {
  buttonsComponent.buttons.push({ url: '', type: 'URL' })
}

function addCopyCodeButton (buttonsComponent: CreateableButtonsComponent) {
  buttonsComponent.buttons.push({ example: [''], type: 'COPY_CODE', text: 'Copiar código de oferta' })
}

function validTemplate () {
  return messageTemplateName.value !== '' && messageTemplateLanguage.value !== '' && existingComponents.value.length > 0 && bodyComponent.value !== undefined
}

async function createMessageTemplate () {
  loading.value = true
  if (messageTemplateName.value === '' || messageTemplateLanguage.value === '' || existingComponents.value.length === 0) {
    toastError('All fields are required')
    loading.value = false
    return
  }
  const accountService = await AccountService.getInstance()
  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string

  const messageTemplateCreation = {
    name: messageTemplateName.value,
    language: messageTemplateLanguage.value,
    category: messageTemplateCategory.value,
    components: existingComponents.value
  }

  try {
    await accountService.createMessageTemplate(wabaId, phoneNumberId, messageTemplateCreation)
    toastSuccess('Message template created')
    await getMessageTemplates(wabaId, phoneNumberId)
  } catch (error) {
    toastError('Error creating message template: ' + error)
  }
  loading.value = false
}

async function deleteMessageTemplate (messageTemplate: MessageTemplate) {
  loading.value = true
  const metaService = await MetaService.getInstance()
  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string
  try {
    await metaService.deleteMessageTemplate(wabaId, phoneNumberId, messageTemplate.id, messageTemplate.name)
    toastSuccess('Message template deleted')
    await getMessageTemplates(wabaId, phoneNumberId)
  } catch (error) {
    toastError('Error deleting message template:' + error)
  }
  loading.value = false
}

async function testMessageTemplate () {
  loading.value = true
  if (testingMessageTemplate.value === undefined) {
    toastError('Message template is needed to send template')
    loading.value = false
    return
  }
  if (testPhoneNumber.value === '') {
    toastError('Test phone number is needed to send template')
    loading.value = false
    return
  }
  const metaService = await MetaService.getInstance()
  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string
  const components = testingMessageTemplate.value.components.filter((component) => {
    return component.type !== 'footer' &&
      (component.type !== 'button' ||
      (component.sub_type !== 'phone_number'))
  })
  testingMessageTemplate.value.components = components

  try {
    const response = await metaService.testMessageTemplate(
      wabaId, phoneNumberId, testPhoneNumber.value, testingMessageTemplate.value
    )
    const message = response.messages[0]
    toastSuccess(`Sent message to ${testPhoneNumber.value}. Message ID: ${message.id} (status: ${message.message_status})`)
  } catch (error) {
    toastError('Error testing message template')
  }
  loading.value = false
}

async function synchronizeMessageTemplates () {
  loading.value = true
  const metaService = await MetaService.getInstance()
  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string
  try {
    await metaService.synchronizeMessageTemplates(wabaId, phoneNumberId)
    toastSuccess('Message templates synchronized')
    await getMessageTemplates(wabaId, phoneNumberId)
  } catch (error) {
    toastError('Error synchronizing message templates')
  }
  loading.value = false
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getphoneNumber (wabaId: string, phoneNumberId: string) {
  try {
    const accountService = await AccountService.getInstance()
    phoneNumber.value = await accountService.getphoneNumber(wabaId, phoneNumberId)
  } catch {
    toastError('Error getting phone number')
  }
}

async function getMessageTemplates (wabaId: string, phoneNumberId: string) {
  try {
    const accountService = await AccountService.getInstance()
    messageTemplates.value = await accountService.getMessageTemplates(wabaId, phoneNumberId)
  } catch {
    toastError('Error getting message templates')
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string

  if (phoneNumberId && wabaId) {
    await getphoneNumber(wabaId, phoneNumberId)
    await getMessageTemplates(wabaId, phoneNumberId)
  } else {
    router.push({ name: 'home' })
  }

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style scoped lang="scss">

.location-params {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.phone-number {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;

  .phone-number-info {

    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    gap: 10px;
    .phone-number-detail {
      .phone-number-name {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .phone-numbers-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .phone-number-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .phone-number {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border: 1px solid black;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }

      .phone-number:hover {
        scale: 1.01;
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.phone-number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;

  .go-back {
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    width: 100%;
  }

  .phone-number-loaded {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    border-radius: 5px;

    .message-templates-and-creation {
      display: flex;
      gap: 10px;

      .message-template-creator {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid black;
        padding: 20px;
        border-radius: 5px;

        .message-template-creation-form {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }

    .message-template-container {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .message-template-list {

        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 600px;
        overflow: auto;
        border-radius: 5px;

        .message-template-test {

          display: flex;
          gap: 10px;

          .message-template-test-input {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
          }
        }

        .message-template {
          display: flex;
          flex-direction: column;
          gap: 10px;
          border: 1px solid black;
          padding: 10px;
          border-radius: 5px;
          width: 100%;
          background: rgba(10, 151, 217, 0.2);

          &.selected {
            background: rgba(10, 151, 217, 0.5);
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          }

          &:hover {
            background: rgba(69, 184, 238, 0.5);
          }

          .message-template-actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }

          .message-template-name {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }

  .webhooks-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;

    .webhooks-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .webhook {
      display: flex;
      flex-direction: row;
      gap: 10px;
      border: 1px solid black;
      padding: 10px;
      border-radius: 5px;
      background: rgba(10, 151, 217, 0.2);

      .webhook-url {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .webhook-enabled {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .delete-webhook {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-self: center;
      }

    }
  }

  .phone-number-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
}
</style>
