<template>
  <div class="whatsapp-business-account-container">
    <div class="go-back">
      <button class="btn btn-primary btn-sm" @click="goBack">Go Back</button>
    </div>
    <div class="whatsapp-business-account-loaded" v-if="!loading">
      <div class="whatsapp-business-account" v-if="whatsappBusinessAccount">
        <div class="whatsapp-business-account-info">
          <div class="whatsapp-business-account-detail">
            <div class="whatsapp-business-account-name">
              Manage Credit Line for {{ whatsappBusinessAccount.name }} ({{ whatsappBusinessAccount.id }})
            </div>
            <div class="whatsapp-business-account-owner">
              <b>Owner Business:</b> {{ whatsappBusinessAccount.owner_business_info.name }} ({{ whatsappBusinessAccount.owner_business_info.id }})
            </div>
            <div class="whatsapp-business-account-id">
              Primary Funding ID: {{ whatsappBusinessAccount.primary_funding_id }}
            </div>
          </div>
        </div>
        <h4> Permissions needed for 👇 </h4>
        <div class="whatsapp-api-actions">
            <button class="btn btn-whatsapp btn-action" @click="shareCreditLine">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Share Credit Line
            </button>
            <button class="btn btn-whatsapp btn-action" @click="verifyCreditLine">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Verify Credit Line
            </button>
            <button class="btn btn-whatsapp btn-action btn-danger" @click="revokeCreditLine">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Revoke Credit Line
            </button>
        </div>
      </div>
      <div v-else>
        Not found
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { WhatsappBusinessAccount } from '../services/AccountService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const whatsappBusinessAccount = ref<WhatsappBusinessAccount | undefined>(undefined)

function goBack () {
  router.back()
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function shareCreditLine () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.shareCreditLine(whatsappBusinessAccount.value.id)
      toastSuccess('Share Credit Line Response: ' + response)
    } catch (error) {
      toastError('Share Credit Line Error: ' + error)
    }
  }
  loading.value = false
}

async function verifyCreditLine () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.verifyCreditLine(whatsappBusinessAccount.value.id)
      toastSuccess('Verify Credit Line Response: ' + JSON.stringify(response))
    } catch (error) {
      toastError('Verify Credit Line Error: ' + error)
    }
  }
  loading.value = false
}

async function revokeCreditLine () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.revokeCreditLine(whatsappBusinessAccount.value.id)
      toastSuccess('Revoke Credit Line Response: ' + response)
    } catch (error) {
      toastError('Revoke Credit Line Error: ' + error)
    }
  }
  loading.value = false
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getWhatsappBusinessAccount (wabaId: string) {
  try {
    const accountService = await AccountService.getInstance()
    whatsappBusinessAccount.value = await accountService.getWhatsappBusinessAccount(wabaId)
  } catch {
    toastError('Error getting whatsapp business account')
    router.push({ name: 'home' })
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  const wabaId = route.params.wabaId as string

  if (wabaId) {
    await getWhatsappBusinessAccount(wabaId)
  } else {
    router.push({ name: 'home' })
  }

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style scoped lang="scss">

.whatsapp-api-actions {
  display: flex;
  gap: 10px;
  width: 600px;
  flex-wrap: wrap;

  .btn-action {
    width: 200px;
  }
}

.whatsapp-business-account {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .whatsapp-business-account-info {

    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    gap: 10px;
    .whatsapp-business-account-detail {
      .whatsapp-business-account-name {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .whatsapp-business-account-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .phone-numbers-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .phone-number-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .phone-number {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border: 1px solid black;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }

      .phone-number:hover {
        scale: 1.01;
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        background: rgba(10, 217, 55, 0.5)      }
    }
  }
}

.whatsapp-business-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  .go-back {
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    width: 100%;
  }

  .whatsapp-business-account-loaded {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
