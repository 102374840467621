<template>
  <div class="whatsapp-business-account-container">
    <div class="go-back">
      <button class="btn btn-primary btn-sm" @click="goBack">Go Back</button>
    </div>
    <div class="whatsapp-business-account-loaded" v-if="!loading">
      <div class="whatsapp-business-account" v-if="whatsappBusinessAccount">
        <div class="whatsapp-business-account-info">
          <div class="whatsapp-business-account-detail">
            <div class="whatsapp-business-account-name">{{ whatsappBusinessAccount.name }} ({{ whatsappBusinessAccount.id }})</div>
            <div class="whatsapp-business-account-owner">
              <b>Owner Business:</b> {{ whatsappBusinessAccount.owner_business_info.name }} ({{ whatsappBusinessAccount.owner_business_info.id }})
            </div>
          </div>
          <div class="phone-numbers-container" v-if="phoneNumbersLoaded">
            {{ phoneNumbers.length }} phone numbers
            <div class="phone-number-list">
              <div class="phone-number" @click="goToPhoneNumber(phoneNumber.id)" v-for="phoneNumber in phoneNumbers" v-bind:key="phoneNumber.id">
                {{phoneNumber.verified_name}}
                ({{phoneNumber.display_phone_number}})
              </div>
            </div>
          </div>
          <div v-else>
            Loading phone numbers...
          </div>
          <div class="subcribed-apps-container">
            <h5>
              Subscribed Apps
            </h5>
            {{ subcribedAppsResponse }}
          </div>
        </div>
        <div class="whatsapp-business-account-actions">
          <button class="btn btn-danger btn-sm" @click="deleteAccount">
            ⚠️ Delete Account
          </button>
          <button class="btn btn-primary btn-sm" @click="geToCreditLine">
            💳 Credit Line
          </button>
        </div>
        <h4> Permissions needed for 👇 </h4>
        <div class="whatsapp-api-actions">
          <div class="synchronize-phone-numbers">
            <button class="btn btn-whatsapp btn-action" @click="synchronizePhoneNumbers">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Synchronize Phone Numbers
            </button>
          </div>
          <div class="get-system-users">
            <button class="btn btn-whatsapp btn-action" @click="getWabaSystemUsers">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Get WABAs System Users
            </button>
          </div>
          <div class="get-system-users">
            <button class="btn btn-whatsapp btn-action" @click="createSystemUser">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Create System User on WABA
            </button>
          </div>
          <div class="get-system-users">
            <button class="btn btn-whatsapp btn-action" @click="subscribeApp">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Subscribe App
            </button>
          </div>
          <div class="get-system-users">
            <button class="btn btn-whatsapp btn-action" @click="getSubscribedApps">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Get Subscribed Apps
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        Not found
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { WhatsappBusinessAccount, PhoneNumber } from '../services/AccountService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const phoneNumbersLoaded = ref(false)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const whatsappBusinessAccount = ref<WhatsappBusinessAccount | undefined>(undefined)

const phoneNumbers = ref<PhoneNumber[]>([])
const subcribedAppsResponse = ref<any>(undefined)

function goBack () {
  router.back()
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function goToPhoneNumber (phoneNumberId: string) {
  router.push({ name: 'phone-number', params: { phoneNumberId } })
}

async function geToCreditLine () {
  router.push({ name: 'credit-line', params: { wabaId: whatsappBusinessAccount.value?.id } })
}

async function createSystemUser () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.createSystemUser(
        whatsappBusinessAccount.value.id,
        '122105581976177242'
      )
      toastSuccess('System user created' + JSON.stringify(response))
    } catch (error) {
      toastError('Error creating system user')
    }
  }
  loading.value = false
}

async function getSubscribedApps () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.getSubscribedApps(whatsappBusinessAccount.value.id)
      subcribedAppsResponse.value = response
      toastSuccess('Subscribed apps updated' + JSON.stringify(response))
    } catch (error) {
      toastError('Error updating subscribed apps')
    }
  }
  loading.value = false
}

async function subscribeApp () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.subscribeApp(whatsappBusinessAccount.value.id)
      toastSuccess('App subscribed' + JSON.stringify(response))
    } catch (error) {
      toastError('Error subscribing app')
    }
  }
  loading.value = false
}

async function getWabaSystemUsers () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.getWabaSystemUsers(whatsappBusinessAccount.value.id)
      toastSuccess('System users updated' + JSON.stringify(response))
    } catch (error) {
      toastError('Error updating system users')
    }
  }
  loading.value = false
}

async function synchronizePhoneNumbers () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const metaService = await MetaService.getInstance()
    try {
      await metaService.synchronizeAccountPhoneNumbers(whatsappBusinessAccount.value.id)
      toastSuccess('Phone numbers synchronized')
      await getPhoneNumbers(whatsappBusinessAccount.value.id)
    } catch (error) {
      toastError('Error synchronizing phone numbers')
    }
  }
  loading.value = false
}

async function deleteAccount () {
  loading.value = true
  if (whatsappBusinessAccount.value) {
    const accountService = await AccountService.getInstance()
    try {
      await accountService.deleteWhatsappBusinessAccount(whatsappBusinessAccount.value.id)
      toastSuccess('Account deleted')
      goBack()
    } catch (error) {
      toastError('Error deleting account: ' + error)
    }
  }
  loading.value = false
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getWhatsappBusinessAccount (wabaId: string) {
  try {
    const accountService = await AccountService.getInstance()
    whatsappBusinessAccount.value = await accountService.getWhatsappBusinessAccount(wabaId)
  } catch {
    toastError('Error getting whatsapp business account')
    router.push({ name: 'home' })
  }
}

async function getPhoneNumbers (wabaId: string) {
  try {
    const accountService = await AccountService.getInstance()
    phoneNumbers.value = await accountService.getWhatsappBusinessAccountPhoneNumbers(wabaId)
  } catch {
    toastError('Error getting whatsapp business account')
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  const wabaId = route.params.wabaId as string

  if (wabaId) {
    await getWhatsappBusinessAccount(wabaId)
    loading.value = false
    await getPhoneNumbers(wabaId)
    phoneNumbersLoaded.value = true
  } else {
    router.push({ name: 'home' })
  }
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style scoped lang="scss">

.whatsapp-api-actions {
  display: flex;
  gap: 10px;
  width: 600px;
  flex-wrap: wrap;

  .btn-action {
    width: 200px;
  }
}

.whatsapp-business-account {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .whatsapp-business-account-info {

    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    gap: 10px;
    .whatsapp-business-account-detail {
      .whatsapp-business-account-name {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .whatsapp-business-account-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .phone-numbers-container {

    .phone-number-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .phone-number {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border: 1px solid black;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }

      .phone-number:hover {
        scale: 1.01;
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        background: rgba(10, 217, 55, 0.5)      }
    }
  }
}

.whatsapp-business-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  .go-back {
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    width: 100%;
  }

  .whatsapp-business-account-loaded {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
