import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'
import WhatsappBusinessAccountView from '../views/WhatsappBusinessAccountView.vue'
import WhatsappBusinessAccountsView from '../views/WhatsappBusinessAccountsView.vue'
import PhoneNumberView from '../views/PhoneNumberView.vue'
import MediaToolsView from '../views/MediaToolsView.vue'
import ConfigClientView from '../views/ConfigClientView.vue'
import MessageTemplatesView from '../views/MessageTemplatesView.vue'
import BusinessProfileView from '../views/BusinessProfileView.vue'
import CreditLineView from '@/views/CreditLineView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: 'about' */ '../views/RegisterView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/whatsapp-business-accounts',
    name: 'whatsapp-business-accounts',
    component: WhatsappBusinessAccountsView
  },
  {
    path: '/media-tools',
    name: 'media-tools',
    component: MediaToolsView
  },
  {
    path: '/config',
    name: 'config-client',
    component: ConfigClientView
  },
  {
    path: '/whatsapp-business-accounts/:wabaId',
    name: 'whatsapp-business-account',
    component: WhatsappBusinessAccountView
  },
  {
    path: '/whatsapp-business-accounts/:wabaId/credit-line',
    name: 'credit-line',
    component: CreditLineView
  },
  {
    path: '/whatsapp-business-accounts/:wabaId/phone-numbers/:phoneNumberId',
    name: 'phone-number',
    component: PhoneNumberView
  },
  {
    path: '/whatsapp-business-accounts/:wabaId/phone-numbers/:phoneNumberId/message_templates',
    name: 'message-templates',
    component: MessageTemplatesView
  },
  {
    path: '/whatsapp-business-accounts/:wabaId/phone-numbers/:phoneNumberId/business_profile',
    name: 'business-profile',
    component: BusinessProfileView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
