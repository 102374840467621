import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import Toast from 'vue-toastification'
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'

const vueApp = createApp(App)

vueApp.use(router)
vueApp.use(Toast, {})
vueApp.mount('#app')

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyDQr0Ny3UW6D7ybQZgvksISY2insFxKQJ0',
  authDomain: 'adereso-bsp.firebaseapp.com',
  projectId: 'adereso-bsp',
  storageBucket: 'adereso-bsp.appspot.com',
  messagingSenderId: '184593836063',
  appId: '1:184593836063:web:097ecb1d75ba9c06c08d2a'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export { app }
