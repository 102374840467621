<template>
  <div class="whatsapp-business-account-container">
    <div class="go-back">
      <button class="btn btn-primary btn-sm" @click="goBack">Go Back</button>
    </div>
    <div class="media-tools-container">
      <h3>Config Client</h3>
      <div class="client-info">
        <h5>Client Name</h5>
        {{  currentEstablishment?.name }}

      </div>

      <div class="download-media-url">
        <h5>Access Token</h5>

        <label for="accessToken">Access Token:</label>
        <input type="text" v-model="credentials.access_token" placeholder="Access token"/>
        <button class="btn btn-primary btn-sm" @click="downloadMedia">Save</button>
        <h5>Credentials</h5>
        <pre style="width: 400px;">
          {{ credentials }}
        </pre>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import { User } from '../services/AuthService/types'
import { Establishment, WhatsappCredentials } from '../services/EstablishmentService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')

const DownloadmediaUrlResponse = ref('')
const mediaID = ref('')
const mediaUrl = ref('')

const defaultCredentials = {
  access_token: '',
  expires_in: '',
  token_type: '',
  scope: '',
  type: '',
  status: '',
  refresh_token: ''
}

const credentials = ref<WhatsappCredentials>(defaultCredentials)

async function downloadMedia () {
  if (mediaUrl.value === '') {
    toastError('Media URL is required')
    return
  }

  const metaService = await MetaService.getInstance()
  try {
    const mediaUrlResp = await metaService.downloadMediaUrl(mediaID.value, mediaUrl.value)
    DownloadmediaUrlResponse.value = mediaUrlResp
  } catch (error) {
    toastError(error as string)
  }
}

function goBack () {
  router.back()
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    toastError(error as string)
  }
}

async function getAccessToken () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const response = await establishmentService.getEstablishmentAccessToken()
    credentials.value = response
  } catch (error) {
    toastError(error as string)
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  await getAccessToken()

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style scoped lang="scss">

.whatsapp-business-account {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .whatsapp-business-account-info {

    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    gap: 10px;
    .whatsapp-business-account-detail {
      .whatsapp-business-account-name {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .whatsapp-business-account-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .phone-numbers-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .phone-number-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .phone-number {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border: 1px solid black;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }

      .phone-number:hover {
        scale: 1.01;
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        background: rgba(10, 217, 55, 0.5)      }
    }
  }
}

.whatsapp-business-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;

  .go-back {
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    width: 100%;
  }

  .whatsapp-business-account-loaded {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
</style>
