<template>
  <div class="phone-number-container">
    <div class="go-back">
      <button class="btn btn-primary btn-sm" @click="goBack">Go Back</button>
    </div>
    <div class="phone-number-loaded" v-if="!loading && phoneNumber && businessProfile">
      <div class="business-profile-container">
        <div class="business-profile">
          <h5>Business Profile</h5>

          <label for="about">Name:</label>
          <input type="text" v-model="businessProfile.about" placeholder="About"/>

          <label for="Address">Address:</label>
          <input type="text" v-model="businessProfile.address" placeholder="Address"/>

          <label for="description">Description:</label>
          <input type="text" v-model="businessProfile.description" placeholder="Description"/>

          <label for="email">Email:</label>
          <input type="text" v-model="businessProfile.email" placeholder="Email"/>

          <label for="picture">Picture:</label>
          <img v-if="businessProfile.profile_picture_url" src="{{ businessProfile.profile_picture_url }}" width="60" class="me-2" alt="" />
          <p v-else>No profile Picture</p>
          <input type="text" v-model="businessProfile.profile_picture_url" placeholder="Profile Picture Url"/>

          <label for="websites">Websites:</label>
          <div class="websites" v-for="(website, websiteIndex) in businessProfile.websites" v-bind:key="websiteIndex">
            <input type="text" v-model="businessProfile.websites[websiteIndex]" placeholder="Website"/>
            <div>
              <button class="btn btn-danger btn-sm" @click="businessProfile.websites.splice(websiteIndex, 1)">X</button>
            </div>
          </div>
          <button v-if="businessProfile.websites.length < 2" class="btn btn-primary btn-sm" @click="businessProfile.websites.push('')">Add Website</button>

          <label for="language">Language:</label>
          <select v-model="businessProfile.vertical">
            <option value="PROF_SERVICES">Servicios Profesionales</option>
          </select>

          <label for="new-picture">Upload new picture:</label>
          <input type="text" v-model="businessProfile.profile_picture_handle" placeholder="New profile Picture Url"/>

        </div>
      </div>
      <h4> Permissions needed for 👇 </h4>
      <div class="phone-number-actions">
        <button class="btn btn-whatsapp btn-sm" @click="saveBusinessProfile">
          <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
          Save Business Profile
        </button>
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { PhoneNumber, WhatsappBusinessProfile } from '../services/AccountService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'
// import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const phoneNumber = ref<PhoneNumber | undefined>(undefined)

const businessProfile = ref<WhatsappBusinessProfile | undefined>(undefined)

function goBack () {
  router.back()
}

async function saveBusinessProfile () {
  if (businessProfile.value) {
    loading.value = true
    const metaService = await MetaService.getInstance()
    const phoneNumberId = route.params.phoneNumberId as string
    const wabaId = route.params.wabaId as string
    try {
      await metaService.updateBusinessProfile(wabaId, phoneNumberId, businessProfile.value)
      toastSuccess('Business Profile Saved')
    } catch (error) {
      toastError('Error saving Business Profile')
    }
    loading.value = false
  }
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getphoneNumber (wabaId: string, phoneNumberId: string) {
  try {
    const accountService = await AccountService.getInstance()
    phoneNumber.value = await accountService.getphoneNumber(wabaId, phoneNumberId)
  } catch {
    toastError('Error getting phone number')
  }
}

async function getBusinessProfile (wabaId: string, phoneNumberId: string) {
  try {
    const metaService = await MetaService.getInstance()
    const response = await metaService.getBusinessProfile(wabaId, phoneNumberId)
    businessProfile.value = response.data[0]
  } catch {
    toastError('Error getting phone number')
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string

  if (phoneNumberId && wabaId) {
    await getphoneNumber(wabaId, phoneNumberId)
    await getBusinessProfile(wabaId, phoneNumberId)
  } else {
    router.push({ name: 'home' })
  }

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

console.log(toastSuccess)

</script>

<style scoped lang="scss">

.phone-number {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;

  .phone-number-info {

    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    gap: 10px;
    .phone-number-detail {
      .phone-number-name {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .phone-numbers-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .phone-number-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .phone-number {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border: 1px solid black;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }

      .phone-number:hover {
        scale: 1.01;
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.phone-number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;

  .go-back {
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    width: 100%;
  }

  .phone-number-loaded {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    border-radius: 5px;

    .business-profile-container {

      width: 90%;
      .business-profile {
        display: flex;
        flex-direction: column;
        .websites {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
        }
      }
    }

    .message-templates-and-creation {
      display: flex;
      gap: 10px;

      .message-template-creator {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid black;
        padding: 20px;
        border-radius: 5px;

        .message-template-creation-form {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }

    .message-template-container {
      display: flex;
      gap: 10px;
      flex-direction: column;

      .message-template-list {

        display: flex;
        flex-direction: column;
        gap: 10px;

        .message-template-test {

          display: flex;
          gap: 10px;

          .message-template-test-input {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
          }
        }

        .message-template {
          display: flex;
          flex-direction: column;
          gap: 10px;
          border: 1px solid black;
          padding: 10px;
          border-radius: 5px;
          width: 100%;
          background: rgba(10, 151, 217, 0.2);

          &.selected {
            background: rgba(10, 151, 217, 0.5);
            scale: 1.01;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
          }

          .message-template-actions {
            display: flex;
            flex-direction: row;
            gap: 10px;
          }

          .message-template-name {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }

  .webhooks-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;

    .webhooks-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .webhook {
      display: flex;
      flex-direction: row;
      gap: 10px;
      border: 1px solid black;
      padding: 10px;
      border-radius: 5px;
      background: rgba(10, 151, 217, 0.2);

      .webhook-url {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .webhook-enabled {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .delete-webhook {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-self: center;
      }

    }
  }

  .phone-number-actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
}
</style>
