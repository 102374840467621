<template>
  <div class='login-container'>
    <div class='login' v-if="!loading">
      <div class='login-form'>
        <h2 class='mb-3'>Login</h2>
        <div class='input'>
          <label for='email'>Email address</label>
          <input
            class='form-control'
            type='text'
            name='email'
            placeholder='email@adress.com'
            v-model='email'
          />
        </div>
        <div class='input'>
          <label for='password'>Password</label>
          <input
            class='form-control'
            type='password'
            name='password'
            placeholder='password123'
            v-model='password'
          />
        </div>
        <div class='alternative-option mt-4'>
          You don't have an account? <span @click='moveToRegister'>Register</span>
        </div>
        <button type='submit' class='mt-4 btn-pers' id='login_button' @click='loginWithEmailAndPassword'>
          Login
        </button>
      </div>
      <div class='alternative-login-options'>
        <button @click='loginWithGoogle' class='btn-google'>
          <img src="../assets/google_logo.png" width="20" class="me-2" alt="" />
          Login with Google
        </button>
        <button @click='loginWithFacebook' class='btn-facebook'>
          <img src="../assets/facebook_logo.png" width="20" class="me-2" alt="" />
          Login with Facebook
        </button>
      </div>
      <div
        class='alert alert-warning alert-dismissible fade show mt-5 d-none'
        role='alert'
        id='alert_1'
      >
        Lorem ipsum dolor sit amet consectetur, adipisicing elit.
        <button
          type='button'
          class='btn-close'
          data-bs-dismiss='alert'
          aria-label='Close'
        ></button>
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</template>

<script setup lang="ts">
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from 'firebase/auth'
import { POSITION, useToast } from 'vue-toastification'
import AuthService from '../services/AuthService/AuthService'
import { useRouter, useRoute } from 'vue-router'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'

const router = useRouter()
const route = useRoute()
const toast = useToast()
const email = ref('')
const password = ref('')
const loading = ref(true)

onMounted(async () => {
  await router.isReady()
  const authService = await AuthService.getInstance()
  if (authService.currentUser.value != null) {
    router.push({ name: 'home', query: route.query })
  }
  loading.value = false
})

async function loginWithEmailAndPassword () {
  const auth = getAuth()
  loading.value = true

  if (email.value === '' || password.value === '') {
    toastError('Email and password are required')
    loading.value = false
    return
  }

  signInWithEmailAndPassword(auth, email.value, password.value)
    .then((result) => {
      const user = result.user
      handleUserLogin(user)
    })
    .catch((error) => {
      toastError(error)
      loading.value = false
    })
}

function moveToRegister () {
  router.push('/register')
}

async function loginWithGoogle () {
  const auth = getAuth()
  const provider = new GoogleAuthProvider()
  loading.value = true

  signInWithPopup(auth, provider)
    .then((result) => {
      // The user is signed in with Google.
      const user = result.user
      handleUserLogin(user)
    })
    .catch((error) => {
      toastError(error)
      loading.value = false
    })
}

async function loginWithFacebook () {
  const auth = getAuth()
  const provider = new FacebookAuthProvider()
  loading.value = true

  signInWithPopup(auth, provider)
    .then((result) => {
      // The user is signed in with Facebook.
      const user = result.user
      handleUserLogin(user)
    })
    .catch((error) => {
      toastError(error)
      loading.value = false
    })
}

async function handleUserLogin (user: any) {
  const token = await user.getIdToken()
  // send token to backend
  const authService = await AuthService.getInstance(token)
  await authService.loginWithIdToken()
  // redirect to dashboard
  router.push('/home')
}

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

</script>

<style lang="scss" scoped>

  .login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .login {
    padding: 50px;
    width: 400px;
    border: 1px solid black;
    padding-top: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: white;
  }

  .alternative-login-options {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      flex-direction: column;
      gap: 10px;
  }

  .btn-google {
    background-color: white;
    color: black;
    border: 1px solid black;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .btn-facebook {
    background-color: white; /* Facebook Blue */
    color: black;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .btn-google i,
  .btn-facebook i {
    font-size: 1.5rem;
    margin-right: 10px;
  }

  .btn-facebook:hover {
    scale: 1.01;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  }

  .btn-google:hover {
    scale: 1.01;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  }
</style>
