<template>
  <div class="home-container">
    <div class="home" v-if="!loading">
      <div class="client-view" v-if="currentUser && currentEstablishment">
        <div class="whatsapp-login" v-if="!currentEstablishment.has_whatsapp_credentials">
          <button class="btn btn-primary" @click="startEmbeddedSignupFlow">
            <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
            WhatsApp Signup
          </button>
        </div>
        <div class="whatsapp-accounts-container">
          <div class="whatsapp-accounts-list" v-if="whatsappBusinessAccounts.length > 0">
            <div class="whatsapp-account" @click="goToWhatsappBusinessAccount(whatsappAccount.id)" v-for="whatsappAccount in whatsappBusinessAccounts" v-bind:key="whatsappAccount.id">
              <div class="whatsapp-account-info">
                <div class="whatsapp-account-name">
                  {{ whatsappAccount.name }}
                </div>
                <div class="whatsapp-account-id">
                  {{ whatsappAccount.id }}
                </div>
              </div>
              <img src="../assets/whatsapp_green.svg" width="40" class="me-2" alt="" />
            </div>
          </div>
          <div class="whatsapp-synchronize-button">
            <p>
              You can't see any of your WABAs? Synchronize your accounts!
            </p>
            <button class="btn btn-whatsapp" @click="SynchronizeWhatsappBusinessAccounts">
              <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
              Get WABAs
            </button>
          </div>
          <div class="config-buttons">
            <div class="media-tools-button">
              <button class="btn btn-whatsapp" @click="goToMediaTools">
                Media Tools
              </button>
            </div>

            <div class="client-config-button">
              <button class="btn btn-config" @click="goToConfigClient">
                Config Client
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="no-client-view" v-else>
        <strong class="no-establishmnet-warning">
          Currently you don't have an establishment 🏛️
        </strong>
        <p class="establishment-invitation-warning">
          You can create one now or wait for an invitation to join an existing one
        </p>
        <div class="create-establishment-input">
          <h2 class='mb-3'>Create Establishment</h2>
          <div class='input'>
            <label for='email'>Establishment's name</label>
            <input
              class='form-control'
              type='text'
              name='name'
              placeholder='Your Brand'
              v-model='establishmentName'
            />
          </div>
          <button type='submit' class='mt-4 btn-pers' id='create_establishment_butoton' @click='createEstablishment'>
            Create
          </button>
        </div>
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { WhatsappBusinessAccount } from '../services/AccountService/types'
import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
const establishmentName = ref('')
const whatsappBusinessAccounts = ref<Array<WhatsappBusinessAccount>>([])

async function goToWhatsappBusinessAccount (wabaId: string) {
  router.push({ name: 'whatsapp-business-account', params: { wabaId } })
}

async function goToMediaTools () {
  router.push({ name: 'media-tools' })
}

async function goToConfigClient () {
  router.push({ name: 'config-client' })
}

async function getAccounts () {
  const accountService = await AccountService.getInstance()
  try {
    whatsappBusinessAccounts.value = await accountService.getWhatsappBusinessAccounts()
  } catch (error) {
    // do nothing
  }
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  if (currentEstablishment.value) {
    await getAccounts()
  }

  loading.value = false
})

async function createEstablishment () {
  if (establishmentName.value === '') {
    toastError('El nombre del establecimiento es requerido')
    return
  }
  const establishmentService = await EstablishmentService.getInstance()
  await establishmentService.createEstablishment(establishmentName.value)
  router.push('/')
}

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

async function SynchronizeWhatsappBusinessAccounts () {
  loading.value = true
  const metaService = await MetaService.getInstance()
  await metaService.synchronizeWhatsappBusinessAccounts()
  await getAccounts()
  loading.value = false
}

/*
 * Create form to request access token from Google's OAuth 2.0 server.
 */
function startEmbeddedSignupFlow () {
  // Facebook's OAuth 2.0 endpoint for requesting an access token
  const oauth2Endpoint = 'https://www.facebook.com/v18.0/dialog/oauth'

  // Parameters to pass to the OAuth 2.0 endpoint.
  const params = {
    client_id: '705015111174876',
    redirect_uri: 'https://whatsapp.adere.so/home',
    response_type: 'code',
    config_id: '367108835655082',
    state: 'pass-through value'
  }

  // Construct the OAuth URL with parameters
  const oauthURL = `${oauth2Endpoint}?${new URLSearchParams(params).toString()}`

  // Open a new window for the OAuth flow
  window.location.replace(oauthURL)
}

</script>

<style scoped lang="scss">

.whatsapp-account {
  display: flex;
  justify-content: space-between;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;

  .whatsapp-account-info {
    .whatsapp-account-id {
      font-weight: bold;
    }
  }
  .whatsapp-account-actions {
    display: flex;
    align-items: center;
    button {
      margin-left: 10px;
    }
  }
}

.whatsapp-account:hover {
  scale: 1.01;
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.whatsapp-accounts-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.whatsapp-synchronize-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
