import { User } from '@/services/AuthService/types'
import { Ref, ref } from 'vue'
import HttpService from '../HttpService/HttpService'

export default class AuthService {
  currentUser: Ref<User | undefined> = ref(undefined)
  updating: Ref<boolean> = ref(false)

  private constructor () {
    // Check if an instance already exists and return it
    if (instance) {
      return instance
    }
    // If no instance exists, create one and assign it to the 'instance' variable
    instance = this
  }

  static async getInstance (token: string | null = null) {
    const authService = new AuthService()
    const httpService = await HttpService.getInstance()

    if (authService.currentUser.value !== undefined) {
      return authService
    }

    if (!token) {
      token = httpService.getToken()
    }

    if (token) {
      httpService.setToken(token)
      try {
        const user = await authService.getCurrentUser()
        authService.currentUser.value = user
      } catch (e) {
        console.log('error getting user from session', e)
      }
    }

    return authService
  }

  async signOut () {
    const httpService = await HttpService.getInstance()
    httpService.removeToken()
    this.currentUser.value = undefined
  }

  async loginWithIdToken (): Promise<User> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post('/api/v1/firebase/login', {})
    return response
  }

  async getCurrentUser (): Promise<User | undefined> {
    if (this.currentUser.value) {
      return this.currentUser.value
    }
    const currentUser = await this.loginWithIdToken()
    this.currentUser.value = currentUser
    return currentUser
  }
}

let instance: AuthService | null = null // Singleton instance
