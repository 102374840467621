import HttpService from '../HttpService/HttpService'
import { Establishment, WhatsappCredentials } from './types'

export default class EstablishmentService {
  currentEstablishment: Establishment | undefined = undefined
  private constructor () {
    // Check if an instance already exists and return it
    if (instance) {
      return instance
    }
    // If no instance exists, create one and assign it to the 'instance' variable
    instance = this
  }

  static async getInstance () {
    const establishmentService = new EstablishmentService()
    return establishmentService
  }

  async createEstablishment (name: string) {
    const httpService = await HttpService.getInstance()
    const response = await httpService.post('/api/v1/establishments', { name })
    return response
  }

  async getEstablishment (): Promise<Establishment> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get('/api/v1/establishments')
    return response
  }

  async getEstablishmentAccessToken (): Promise<WhatsappCredentials> {
    const httpService = await HttpService.getInstance()
    const response = await httpService.get('/api/v1/establishments/access_token')
    return response
  }

  async getCurrentEstablishment (): Promise<Establishment> {
    if (this.currentEstablishment) {
      return this.currentEstablishment
    }
    const currentEstablishment = await this.getEstablishment()
    this.currentEstablishment = currentEstablishment
    return currentEstablishment
  }

  async removeCurrentEstablishment () {
    this.currentEstablishment = undefined
  }
}

let instance: EstablishmentService | null = null // Singleton instance
