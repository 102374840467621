<template>
  <div class="phone-number-container">
    <div class="go-back">
      <button class="btn btn-primary btn-sm" @click="goBack">Go Back</button>
    </div>
    <div class="phone-number-loaded" v-if="!loading && phoneNumber">
      <div class="phone-number">
        <div class="phone-number-info">
          <div class="phone-number-detail">
            <div class="phone-number-name">Name: {{ phoneNumber.verified_name }}</div>
            <div class="phone-number-id"><b>ID:</b> {{ phoneNumber.id }}</div>
            <div class="phone-number-id"><b>Number:</b> {{ phoneNumber.display_phone_number }}</div>
            <div class="go-to-buttons">
              <button class="btn btn-primary btn-sm" @click="goToMessageTemplates">Message Templates</button>
              <button class="btn btn-primary btn-sm" @click="goToBusinessProfile">Business Profile</button>
            </div>
          </div>
        </div>
        <div class="webhooks-container">
          <h5>Webhooks Config</h5>
          <div class="webhooks-list">
            <div class="webhook" v-for="webhook in phoneNumber.webhooks" v-bind:key="webhook.id">
              <div class="webhook-url">
                <strong>URL:</strong>
                <input type="text" v-model="webhook.url" placeholder="https://..."/>
              </div>
              <div class="webhook-enabled">
                <strong>Enabled:</strong>
                <input type="checkbox" v-model="webhook.enabled" />
                <div class="error-counter">
                  <strong>Errors:</strong>
                  <span>{{ webhook.error_count }} / {{ webhook.error_limit }}</span>
                </div>
                <div class="disabled-at">
                  <strong>Disabled at:</strong>
                  <span>{{ webhook.disabled_at }}</span>
                </div>
              </div>
              <div class="delete-webhook">
                <button class="btn btn-danger btn-sm" @click="deleteWebhook(webhook.id)">
                  Delete
                </button>
              </div>
            </div>
          </div>
          <div class="webhook-actions">
            <button class="btn btn-secondary btn-sm" @click="addWebhook">
              Add Webhook
            </button>
          </div>
        </div>
        <div class="health_status_container">
          <h5>Testing mode</h5>
          <input type="checkbox" v-model="phoneNumber.testing_mode" />
          &nbsp;
          <span>
            <b>Testing mode:</b>
            <br>
            ⚠️ Only outbound message templates will be simulated
            <br>
            the message will not be sent to whatsapp, and a random wamid will be generated.
            <br>
            a message status will be generated after 1-5 seconds.
            <br>
            (sent, delivered, read and failed will be equally likely.)
          </span>
        </div>
        <div class="health_status_container">
          <h5>Health Status</h5>
          <div class="health_status">
            <pre>{{ healthStatusResponse }}</pre>
          </div>
        </div>
      </div>
      <div class="phone-number-actions" v-if="phoneNumber">
        <div class="phone-number-action">
          <button class="btn btn-primary btn-xs" @click="savePhoneNumber">
            Save
          </button>
        </div>
        <div class="phone-number-action">
          <button class="btn btn-danger btn-xs" @click="deletePhoneNumber">⚠️ Delete</button>
        </div>
      </div>
      <h4> Permissions needed for 👇 </h4>
      <div class="phone-number-actions">
        <div class="register-phone">
          <button class="btn btn-whatsapp btn-sm" @click="registerPhone">
            <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
            Register Phone with pin
          </button>
          <input type="text" v-model="registerPin" placeholder="Pin"/>
        </div>
        <button class="btn btn-whatsapp btn-sm" @click="getPhoneHealthStatus">
          <img src="../assets/whatsapp_green.svg" width="20" class="me-2" alt="" />
          Get Health Status
        </button>
        <br>
        <p>
          *Also needed for Sending and receiving messages
        </p>
      </div>
    </div>
    <SpinnerLoader v-else/>
  </div>
</template>

<script setup lang="ts">

import { useRouter, useRoute } from 'vue-router'
import AuthService from '../services/AuthService/AuthService'
import { onMounted, ref } from 'vue'
import SpinnerLoader from '../components/loaders/SpinnerLoader.vue'
import { User } from '../services/AuthService/types'
import { Establishment } from '../services/EstablishmentService/types'
import { PhoneNumber } from '../services/AccountService/types'
// import AccountService from '../services/AccountService/AccountService'
import { POSITION, useToast } from 'vue-toastification'
import EstablishmentService from '@/services/EstablishmentService/EstablishmentService'
import AccountService from '@/services/AccountService/AccountService'
import MetaService from '@/services/MetaService/MetaService'

const router = useRouter()
const route = useRoute()
const toast = useToast()

const loading = ref(true)
const currentUser = ref<User | undefined>(undefined)
const currentEstablishment = ref<Establishment | undefined>(undefined)
// const establishmentName = ref('')
const phoneNumber = ref<PhoneNumber | undefined>(undefined)

const healthStatusResponse = ref<any>({})

const registerPin = ref('')

function goBack () {
  router.back()
}

function goToMessageTemplates () {
  router.push({ name: 'message-templates', params: { phoneNumberId: phoneNumber.value?.id, wabaId: phoneNumber.value?.waba_id } })
}

function goToBusinessProfile () {
  router.push({ name: 'business-profile', params: { phoneNumberId: phoneNumber.value?.id, wabaId: phoneNumber.value?.waba_id } })
}

async function savePhoneNumber () {
  loading.value = true
  if (phoneNumber.value) {
    const accountService = await AccountService.getInstance()
    try {
      await accountService.updatePhoneNumber(phoneNumber.value)
      toastSuccess('Phone updated')
    } catch (error) {
      toastError('Error updating account: ' + error)
    }
  }
  loading.value = false
}

async function registerPhone () {
  loading.value = true

  if (!registerPin.value) {
    toastError('Pin is required')
    loading.value = false
    return
  }

  if (phoneNumber.value) {
    const metaService = await MetaService.getInstance()
    try {
      await metaService.registerPhoneNumber(phoneNumber.value, registerPin.value)
      toastSuccess('Phone registered')
    } catch (error) {
      toastError('Error registering phone: ' + error)
    }
  }
  loading.value = false
}

async function getPhoneHealthStatus () {
  loading.value = true
  if (phoneNumber.value) {
    const metaService = await MetaService.getInstance()
    try {
      const response = await metaService.getPhoneHealthStatus(phoneNumber.value)
      healthStatusResponse.value = response
      toastSuccess('Phone health status: ' + JSON.stringify(response))
    } catch (error) {
      toastError('Error getting phone health status: ' + error)
    }
  }
  loading.value = false
}

function deleteWebhook (webhookId: string) {
  if (phoneNumber.value) {
    delete phoneNumber.value.webhooks[webhookId]
  }
}

function addWebhook () {
  if (phoneNumber.value) {
    // generate random webhook id
    const webhookId = Math.random().toString(36).substring(7)
    const webhook = {
      id: webhookId,
      url: '',
      enabled: false
    }
    phoneNumber.value.webhooks[webhook.id] = webhook
  }
}

async function deletePhoneNumber () {
  loading.value = true
  const accountService = await AccountService.getInstance()
  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string
  try {
    await accountService.deletePhoneNumber(wabaId, phoneNumberId)
    toastSuccess('Phone number deleted')
    goBack()
  } catch (error) {
    toastError('Error deleting phone number')
  }
  loading.value = false
}

async function getEstablishment () {
  const establishmentService = await EstablishmentService.getInstance()
  try {
    const establishment = await establishmentService.getCurrentEstablishment()
    currentEstablishment.value = establishment
  } catch (error) {
    // do nothing
  }
}

async function getCurrentUser () {
  const authService = await AuthService.getInstance()
  currentUser.value = authService.currentUser.value
  if (authService.currentUser.value == null) {
    router.push({ name: 'login', query: route.query })
  }
}

async function getphoneNumber (wabaId: string, phoneNumberId: string) {
  try {
    const accountService = await AccountService.getInstance()
    phoneNumber.value = await accountService.getphoneNumber(wabaId, phoneNumberId)
  } catch {
    toastError('Error getting phone number')
  }
}

onMounted(async () => {
  await router.isReady()

  await getCurrentUser()

  await getEstablishment()

  const phoneNumberId = route.params.phoneNumberId as string
  const wabaId = route.params.wabaId as string

  if (phoneNumberId && wabaId) {
    await getphoneNumber(wabaId, phoneNumberId)
  } else {
    router.push({ name: 'home' })
  }

  loading.value = false
})

function toastError (errorMessage: string) {
  try {
    toast.error(String(errorMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

function toastSuccess (successMessage: string) {
  try {
    toast.success(String(successMessage), {
      position: POSITION.BOTTOM_LEFT
    })
  } catch (error) {
    console.log('error on toast:', error)
  }
}

console.log(toastSuccess)

</script>

<style scoped lang="scss">

.phone-number-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  place-items: baseline;
}

.register-phone {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.phone-number {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .phone-number-info {

    display: flex;
    flex-direction: column;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    gap: 10px;
    .phone-number-detail {
      .phone-number-name {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .phone-numbers-container {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .phone-number-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      .phone-number {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border: 1px solid black;
        padding: 10px;
        border-radius: 5px;
        width: 100%;
      }

      .phone-number:hover {
        scale: 1.01;
        cursor: pointer;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.phone-number-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;

  .go-back {
    display: flex;
    justify-content: flex-start;
    margin-left: 100px;
    width: 100%;
  }

  .phone-number-loaded {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    border-radius: 5px;

  .go-to-buttons {
    display: flex;
    gap: 10px;
  }

  .webhooks-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid black;
    padding: 20px;
    border-radius: 5px;
    background: rgba(10, 151, 217, 0.2);

    .webhooks-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .webhook {
      display: flex;
      flex-direction: row;
      gap: 10px;
      border: 1px solid black;
      padding: 10px;
      border-radius: 5px;
      background: rgba(10, 151, 217, 0.2);

      .webhook-url {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .webhook-enabled {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .delete-webhook {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-self: center;
      }

    }
  }
}
}
</style>
